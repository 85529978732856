const config = {
  gatsby: {
    pathPrefix: "/",
    siteUrl: "https://yoeubi.github.io",
    gaTrackingId: "UA-131587027-1"
  },
  header: {
    logo:
      "https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/favicon.png",
    logoLink: "https://yoeubi.github.io/",
    title: "Frontend - Today I learned",
    githubUrl: "https://github.com/yoeubi",
    helpUrl: "",
    tweetText: "",
    links: [{ text: "", link: "" }],
    search: {
      enabled: false,
      indexName: "",
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY
    }
  },
  sidebar: {
    forcedNavOrder: [
      "/javascript",
      "/typescript",
      "/css",
      "/frontend",
      "/webpack",
      "/data-structure",
      "/network",
      "/unix",
      "/operating-system",
      "/c",
      "/database"
    ],
    collapsedNav: [
      "/javascript",
      "/typescript",
      "/css",
      "/frontend",
      "/webpack",
      "/data-structure",
      "/network",
      "/unix",
      "/operating-system",
      "/c",
      "/database"
    ],
    links: [{ text: "Github", link: "https://github.com/yoeubi" }],
    frontline: false,
    ignoreIndex: true
  },
  siteMetadata: {
    title: "Yoeubi TIL",
    description: "Yoeubi Today I learned",
    ogImage: null,
    docsLocation: "https://github.com/yoeubi",
    favicon: "https://graphql-engine-cdn.hasura.io/img/hasura_icon_black.svg"
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: "Gatsby Gitbook Starter",
      short_name: "GitbookStarter",
      start_url: "/",
      background_color: "#6b37bf",
      theme_color: "#6b37bf",
      display: "standalone",
      crossOrigin: "use-credentials",
      icons: [
        {
          src: "src/pwa-512.png",
          sizes: `512x512`,
          type: `image/png`
        }
      ]
    }
  }
};

module.exports = config;
